const scroll = ({ top }: { top: number }) => {
  window.scrollTo({
    top,
    behavior: 'smooth',
  });
};

export const scrollToElement = ({ el }: { el: HTMLElement }) => {
  scroll({ top: el.offsetTop });
};

export const scrollToHash = ({ hash }: { hash: string }) => {
  if (!hash) {
    return;
  }
  const el: HTMLElement = document.querySelector(hash);
  if (el) {
    scrollToElement({ el });
  }
};

export const applySmoothScroll = () => {
  document.querySelectorAll('a[href*="#"]').forEach((a: HTMLAnchorElement) => {
    const { pathname } = new URL(a.href);
    if (pathname === window.location.pathname) {
      a.addEventListener('click', (event) => {
        event.preventDefault();
        scrollToHash({ hash: a.hash });
      });
    }
  });
};
