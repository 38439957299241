// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SendRequestFinished__title--tdXoQ{font-weight:700;margin:10px 0 25px;text-align:center}.SendRequestFinished__title--tdXoQ [class^=icon-]{margin-right:6px;position:relative;top:1px}", "",{"version":3,"sources":["webpack://./assets/src/js/components/listing/SendRequestFinished.vue"],"names":[],"mappings":"AAEA,mCACE,eAAA,CACA,kBAAA,CACA,iBAAA,CAEA,kDACE,gBAAA,CACA,iBAAA,CACA,OAAA","sourcesContent":["@import '@/scss/base/variables';\n\n.title {\n  font-weight: bold;\n  margin: 10px 0 25px;\n  text-align: center;\n\n  [class^='icon-'] {\n    margin-right: 6px;\n    position: relative;\n    top: 1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "SendRequestFinished__title--tdXoQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
