// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignupCompleteModal__content--CjYdP{font-size:16px;text-align:center}.SignupCompleteModal__title--e9Bdq{font-size:24px;font-weight:700;margin:20px 0}.SignupCompleteModal__lead--XWGp2{display:inline-block;font-size:16px;margin:0 0 15px;text-align:left}@media only screen and (max-width:639px){.SignupCompleteModal__icon--xTZPP{height:auto;width:66px}.SignupCompleteModal__title--e9Bdq{font-size:18px}}", "",{"version":3,"sources":["webpack://./assets/src/js/components/organisms/modals/SignupCompleteModal.vue"],"names":[],"mappings":"AAEA,qCACE,cAAA,CACA,iBAAA,CAUF,mCACE,cAAA,CACA,eAAA,CACA,aAAA,CAOF,kCACE,oBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CApBA,yCADF,kCAEI,WAAA,CACA,UAAA,CAIJ,mCAMI,cAAA,CAVA","sourcesContent":["@import '@/scss/base/variables';\n\n.content {\n  font-size: 16px;\n  text-align: center;\n}\n\n.icon {\n  @media only screen and (max-width: $breakpoint-s) {\n    height: auto;\n    width: 66px;\n  }\n}\n\n.title {\n  font-size: 24px;\n  font-weight: bold;\n  margin: 20px 0;\n\n  @media only screen and (max-width: $breakpoint-s) {\n    font-size: 18px;\n  }\n}\n\n.lead {\n  display: inline-block;\n  font-size: 16px;\n  margin: 0 0 15px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "SignupCompleteModal__content--CjYdP",
	"title": "SignupCompleteModal__title--e9Bdq",
	"lead": "SignupCompleteModal__lead--XWGp2",
	"icon": "SignupCompleteModal__icon--xTZPP"
};
module.exports = ___CSS_LOADER_EXPORT___;
