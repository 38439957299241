// 最初のerrorクラス（CSS Modules）までスクロールする
export const scrollToError = () => {
  const firstErrorEl = document.querySelector('[class*="__error--"]');
  if (firstErrorEl) {
    firstErrorEl.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};
