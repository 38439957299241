// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CartModal__text--HkwOs{font-size:18px;font-weight:700}@media only screen and (max-width:639px){.CartModal__modal--XGMcM button{font-size:14px;height:46px;padding:12px}}", "",{"version":3,"sources":["webpack://./assets/src/js/components/www.iichi.com/organisms/modals/CartModal.vue"],"names":[],"mappings":"AAUA,wBACE,cAAA,CACA,eAAA,CATA,yCADF,gCAEI,cAAA,CACA,WAAA,CACA,YAAA,CAAA","sourcesContent":["@import '@/scss/base/variables';\n\n.modal button {\n  @media only screen and (max-width: $breakpoint-s) {\n    font-size: 14px;\n    height: 46px;\n    padding: 12px;\n  }\n}\n\n.text {\n  font-size: 18px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "CartModal__text--HkwOs",
	"modal": "CartModal__modal--XGMcM"
};
module.exports = ___CSS_LOADER_EXPORT___;
